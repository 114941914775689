export default function Footer () {

    return (
        <div>
            <footer className="site-footer">
                <div className="footer-content">
                    <div className="footer-section about">
                        <h3>About CollegeNPV</h3>
                        <p>CollegeNPV conducts independent analysis of expected financial outcomes of universities for typical entering students for informational purposes. Return on investment calculations 
                            are estimates based on publicly available data from the U.S. Department of Education and Bureau of Labor Statistics, information provided by the user and other expert assumptions 
                            made by CollegeNPV.
                            Real outcomes will vary. This information can change over time and might not apply to you. Images of universities are artistic representations
                            and may not be accurate depictions. For more information, read our <a className="footer-link" href={`/methodology`}>methodology</a>.
                            </p>
                    </div>
                    <div className="footer-section contact">
                        <h3>Contact Us</h3>
                        <p>Email: contact@collegenpv.com</p>
                        <p>Reddit: <a className="footer-link" href={"https://www.reddit.com/user/CollegeNPV/"} target="_blank" rel="noopener noreferrer">u/CollegeNPV</a></p>
                        <p>X: <a className="footer-link" href={"https://x.com/CollegeNPV"} target="_blank" rel="noopener noreferrer">@CollegeNPV</a></p>
                    </div>
{/*                     <div className="footer-section social-media">
                        <h3>Follow Us</h3>
                        <p><a href="#">Facebook</a> | <a href="#">Twitter</a> | <a href="#">LinkedIn</a></p>
                    </div> */}
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 CollegeNPV LLC, All rights reserved.</p>
                    <p>
                        {/* <a href={`/termsandconditions`}>Terms and Conditions</a> |  */}
                        <a className="footer-link" href={`/privacy`}>Privacy Policy</a>
                    </p>
                </div>
            </footer>
        </div>
    );
}