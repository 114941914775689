import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import { UserContext } from "./UserContext";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as Icon} from '../src/icon.svg';
import ValuationChart from './FairTuitionBar';

export default function CollegeRank({UNITID,
                                    RANK,
                                    INSTNM,
                                    CITY,
                                    STABBR,
                                    UGDS,
                                    NET_NPV,
                                    ADM_RATE,
                                    ACTCM25,
                                    ACTCM75,
                                    SATCM25,
                                    SATCM75,
                                    MONTHLY_INCOME,
                                    DEBT_PAYMENT,
                                    C150,
                                    MD_EARN_WNE_4YR,
                                    GRAD_DEBT_MDN,
                                    WDRAW_DEBT_MDN,
/*                                     UNI_FAIRVALUE,
                                    NET_PRICE */
                                }) {
    
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';

    const [showTooltip, setShowTooltip] = useState(false);
    // Function to format Decimal128 value to US dollar format
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        }).format(value);
    };

    function formatNumberWithCommas(number) {
        return number ? number.toLocaleString() : "";
    }

    function formatNumberAsPercentage(number, decimalPlaces = 0) {
        if (number !== null && number !== undefined) {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    }

    function toggleTooltip() {
        setShowTooltip(!showTooltip);
    }

   // const postDebtInc = monthly_income + debt_pmt; 

    //const hseIncome = 3550;
    //const netIncomeHSE = postDebtInc - hseIncome;
    
    // Dynamic image URL based on UNITID
    const imageUrl = `${UNITID}.jpg`;

    const navigate = useNavigate();

    const navigateAndScrollToTop = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    const { userInfo } = useContext(UserContext);

    const handleCardPlusClick = (UNITID) => {
        if (!userInfo) { // Check if user is not logged in
            navigate('/register');
        } else {
            // User is logged in, proceed with adding to 'My List'
            fetch(`${apiUrl}/save-college`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Important for sending cookies with the request
                body: JSON.stringify({ UNITID }),
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to save college. Please try again.');
            })
            .then(data => {
                console.log("College saved successfully:", data);
                // Optionally, update UI or state to reflect the added college
            })
            .catch(error => {
                console.error("Error saving college:", error);
                // Handle error (e.g., show error message to user)
            });
        }
    };

    const fontColor = NET_NPV === null ? '#ccc' : (NET_NPV >= 0 ? '#05BFDD' : '#F92654');



    return (
    <div className="fos-wrapper">
        <div className="uni-card">
            <div className="fos-card-top">
                <div className="card-ranking">
                    {RANK !== null ? `#${RANK} in expected outcomes` : "Unranked"}</div>
                <div>
                    {!userInfo && <button className="card-plus" onClick={() => handleCardPlusClick(UNITID)}>Sign Up</button>}
                    {userInfo && <button className="card-plus" onClick={() => handleCardPlusClick(UNITID)}>+ My List</button>}
                </div>
            </div>
            <div className="fos-horizontal-divider"></div>
            <div className="fos-card-info">
                        <div className="fos-card-info-left">
                            <h3 className="fos-card-name">
                                <a
                                    href={`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigateAndScrollToTop(`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`);
                                    }}
                                    aria-label={`View more information about ${INSTNM}`}
                                >
                                {INSTNM}
                                </a>
                            </h3>
                            <div className="uni-location-container">
                                <div className="uni-location">📍 {CITY}, {STABBR}</div>
                                <div className="uni-location">{formatNumberWithCommas(UGDS)} enrolled students</div>
                            </div>
                        </div>
                        <div className="fos-card-info-right">
                            <a 
                                href={`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateAndScrollToTop(`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`);
                                }}
                                aria-label={`View more information about ${INSTNM}`}
                            >
                                <LazyLoadImage className="fos-card-img" 
                                    src={`${blobUrl}/${imageUrl}`} 
                                    alt={`${INSTNM} Image`}
                                    effect="blur"
                                    onError={(e) => {
                                        if (e.target.src !== `${blobUrl}/default.jpg`) {
                                            e.target.onerror = null; // Prevent further triggers of onError
                                            e.target.src = `${blobUrl}/default.jpg`; // Fallback image path
                                        }
                                    }}
                                    visibleByDefault={false}
                                    threshold={100}
                                />
                            </a>
                        </div>
                </div>
            <div className="calculation2">   
                <div className = "valuation-section">
                    <div className="valuation">
                        <span className="valuation-type">Return On Investment:</span>
                        <span className="valuation-value" 
                            onClick={toggleTooltip}
                            style={{ color: fontColor }}
                        >
                            {NET_NPV !== null ? formatCurrency(NET_NPV) : "N/A"}
                            <span> <Icon style={{ fill: fontColor }} alt="Valuation information" /></span>
                            {showTooltip && (
                            <div
                                className={`tooltip ${NET_NPV >= 0 ? 'tooltip-after-positive' : 'tooltip-after-negative'}`}
                                style={{ backgroundColor: fontColor, borderColor: fontColor }}
                            >
                                <div className="tooltip-title">What drives this estimate?</div>
                                <div className="item2">
                                    <span className="label2">University Completion Rate:</span>
                                    <span className="value2">{formatNumberAsPercentage(C150)}</span>
                                </div>
                                <div className="item2">
                                    <span className="label2">Median Graduate Income:</span>
                                    <span className="value2">{formatCurrency(MD_EARN_WNE_4YR)}</span>
                                </div>
                                <div className="item2">
                                    <span className="label2">Median Debt:</span>
                                    <span className="value2">{formatCurrency(GRAD_DEBT_MDN)}</span>
                                </div>
                                <div className="methodology-desc">
                                    <p>
                                        For more information, read our <a className="methodology-link" href="/methodology">methodology</a>.
                                    </p>
                                </div>
                            </div>
                            )}
                        </span>
                    </div>
{/*                     <div className="valuation">
                        <span className="valuation-type">Fair Tuition Estimate:</span>
                        <ValuationChart UNI_FAIRVALUE={UNI_FAIRVALUE} NET_PRICE={NET_PRICE} />
                    </div> */}
                </div>
                <div className="calculation2-header">
                    <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="brandmark" />
                </div>
            </div>
            <div className="calculation3">
                <div className="item2">
                    <span className="label2">Acceptance:</span>
                    <span className="value2">{formatNumberAsPercentage(ADM_RATE)}</span>
                </div>
                <div className="item2">
                    <span className="label2">ACT Range:</span>
                    <span className="value2">{ACTCM25}-{ACTCM75}</span>
                </div>
                <div className="item2">
                    <span className="label2">SAT Range:</span>
                    <span className="value2">{SATCM25}-{SATCM75}</span>
                </div>
                <div className="line"></div>
            </div>
        </div>
    </div>
    );
}