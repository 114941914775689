import React, { useEffect, useState } from 'react';

export default function CollegePageOutcomes ({
    UNITID,
    currName,
    currAlias,
    netNPV,
    currRank,
    compRate,
    gradIncome4,
    gradDebt,
    nonGradDebt,
    defaultRate3,
    hseThreshold8,
    pctFedLoan
}) {

    const [topFields, setTopFields] = useState([]);
    const [fieldCount, setFieldCount] = useState(0);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        }).format(value);
    };

    function formatPercentage(number, decimalPlaces) {
        if (typeof number === "number") {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    };

    const fontColor = netNPV >= 0 ? '#05BFDD' : '#F92654';

    const apiUrl = process.env.REACT_APP_API_URL || '';

    const pcipEmojiMap = {
        1: "🚜",
        3: "🏞️",
        4: "📐",
        5: "🌈",
        9: "🖋️",
        10: "👄",
        11: "👨‍💻",
        13: "🏫",
        14: "⚙️",
        15: "👩‍🔧",
        16: "🈸",
        19: "👪",
        22: "⚖️",
        23: "🇬🇧",
        24: "🎭",
        26: "🧬",
        27: "🔢",
        30: "📚",
        31: "🚴",
        38: "☯️",
        39: "✝️",
        40: "🚀",
        42: "🧠",
        43: "🔫",
        44: "🦮",
        45: "♟️",
        46: "👷",
        47: "🛠️",
        49: "🚢",
        50: "🎨",
        51: "👩‍⚕️",
        52: "💸",
        54: "🏺"
    }

    function getPCIPEmoji(pcipCode) {
        const codeAsInt = parseInt(pcipCode, 10);
    
        // Check if the pcipCode exists in the map
        if (pcipEmojiMap.hasOwnProperty(codeAsInt)) {
            return pcipEmojiMap[codeAsInt];
        }
    
        // Return a default emoji or null if not found
        return null; // Or return a default emoji like "📚" for not found
    }

    useEffect(() => {
        const fetchTopFields = async () => {
            try {
                const response = await fetch(`${apiUrl}/topfields?UNITID=${UNITID}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                // Append PCIP to each field
                const fieldsWithPCIP = data.results.map(field => {
                    const cipCode = field.CIPCODE.toString();
                    let pcip;

                    if (cipCode.length === 3) {
                        pcip = Number(cipCode.substring(0, 1));
                    } else if (cipCode.length === 4) {
                        pcip = Number(cipCode.substring(0, 2));
                    }

                    return {
                        ...field,
                        PCIP: pcip
                    };
                });

                setTopFields(fieldsWithPCIP);
                setFieldCount(fieldsWithPCIP.length);
            } catch (error) {
                console.error("Failed to fetch top 5 fields by NET_NPV:", error);
            }
        };

        if (UNITID) {
            fetchTopFields();
            console.log(fieldCount);
        }
    }, [UNITID, apiUrl]);

    return (
        <div>
            {currAlias && !currAlias.includes(' ') ? ( //Only one word aliases
                        <h3 className="cp-valuationtitle">Is {currAlias} a good school?</h3>
                    ) : (
                        <h3 className="cp-valuationtitle">Outcomes</h3>
                    )}
            {netNPV && (
                <div className="cp-valuation">
                    <div className = "cp-valuation-section">
                            <div className="cp-def-valuation">
                                <span className="cp-def-valuation-type">Return on Investment:</span>
                                <span className="cp-def-valuation-value" 
                                    style={{ color: fontColor }}
                                >{netNPV !== null ? formatCurrency(netNPV) : "N/A"}</span>
                                <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="cp-brandmark" />
                                <div className="cp-def-factors">Key Factors Driving this Estimate:</div>
                                <div className="cp-def-factorlist">
                                    <ul>
                                        <li><span style={{ color: fontColor }}>{formatPercentage(compRate, 0)} of students graduate</span> within 150% of the expected completion time.</li>
                                        <li>4 years post-grad, the <span style={{ color: fontColor }}>median graduate earns {formatCurrency(gradIncome4)}.</span></li>
                                        <li>Median <span style={{ color: fontColor }}>debt obligations are {formatCurrency(gradDebt)} for completers.</span></li>
                                    </ul>
                                </div>
                            </div>                        
                    </div>
                </div>
            )}
            {!netNPV && (
                <div className="cp-valuation">
                    <div className = "cp-valuation-section">
                            <div className="cp-def-valuation">
                                <span className="cp-def-valuation-type">Expected Value is not available due to insufficient data.</span>
                                <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="cp-brandmark" />
                            </div>                        
                    </div>
                </div>
            )}
        {netNPV && fieldCount > 0 && (
                <div>
                    <h3>What are the best majors at {currAlias || currName}?</h3>
                    <table className="college-outcomes-table">
                        <thead>
                            <tr>
                                <th>Field of Study</th>
                                <th>Return on Investment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topFields.map((field, index) => (
                                <tr key={index} className="college-outcomes-row">
                                    <td className="college-outcomes-major">
                                        <a href={`/majors?DEGREE=${encodeURIComponent(field.CIPDESC.replace(/[^a-zA-Z0-9 ]/g, ''))}&CIPCODE=${field.CIPCODE}`}>{index + 1}. {getPCIPEmoji(field.PCIP)} {field.CIPDESC.replace(/\./g, '')}</a>
                                    </td>
                                    <td
                                        className="college-outcomes-roi"
                                    >
                                        {field.NET_NPV !== null ? formatCurrency(field.NET_NPV) : "N/A"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <a className="college-outcomes-link" href={`/programrankings?query=${encodeURIComponent(currName)}&page=1&sort=rank_desc`}>View all fields of study</a>
                </div>
            )}
            <h4>Other Factors to Consider:</h4>
                <div className="cp-outcome-metrics">
                        <div>
                            <div>% of Students Receiving a Federal Student Loan:</div>
                                <div className="cp-outcome-metric-value">{formatPercentage(pctFedLoan, 1)}</div>
                        </div>                    
                        <div>
                            <div>% Earning More than High School Grads After 8 Years:</div>
                                <div className="cp-outcome-metric-value">{formatPercentage(hseThreshold8, 1)}</div>
                        </div>
                        <div>
                            <div>3 Year Loan Default Rate:</div>
                                <div className="cp-outcome-metric-value">{formatPercentage(defaultRate3, 1)}</div>
                        </div>
                </div>
        </div>
    )
}