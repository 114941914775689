import React from 'react';
import AdInArticle from '../AdInArticle';
import useDocumentTitle from '../useDocumentTitle';

function MethodologyPage() {
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';
    useDocumentTitle('College Ranking Methodology');

  return (
    <div className="methodology-main">
      <section className="foreword">
        <h2>About Us</h2>
            <p>Every year, high school students face pivotal decisions about their educational futures. CollegeNPV simplifies this process, using data to guide students towards financially sound educational paths.</p>
            <p>By leveraging public data, we provide insights into the value of a college education, empowering informed decisions.</p>
      </section>
      <section className="valuation-estimates">
        <h2>How We Rank Schools</h2>
            <p>To estimate return on investment, CollegeNPV calculates the net present value of schools and programs. The following factors are the primary drivers behind each ROI estimate:</p>
            <ul>
                <li>Graduation likelihood and timeframe</li>
                <li>Expected earnings versus debt for graduates and non-graduates</li>
                <li>Opportunity costs of not entering the workforce immediately after high school</li>
            </ul>
            <p>
              <strong>Net Present Value (NPV):</strong>
            </p>
            <p>
              Net Present Value (NPV) is a financial calculation that estimates the value of an investment or a series of cash flows over time, considering the fact that money possessed today is worth more than the same amount in the future due to its potential earning capacity.
            </p>
            <p>
            In simpler terms, imagine you're deciding whether to invest in a project or not. You expect to get some money back from this investment in the future, but you know that money received in the future isn't as valuable as money you have right now (because you could be earning interest on that money or investing it elsewhere).
            </p>
            <p>
            The NPV calculation helps you figure out if the money you'll get back in the future (after considering how much it's "worth" today) is more than the money you put in.
            </p>
            <ul>
              <li>If the NPV is positive: It means that the investment is expected to generate more value than it costs, so it's probably a good investment.</li>
              <li>If the NPV is negative: It means the investment may not be worth it from a financial perspective.</li>
            </ul>
            <p>
              NPV is applicable to college decisions, because it captures the trade-off between forgoing short-term income (by choosing to study instead of work), in exchange for the prospect of higher income after graduation. By taking into account likelihood of completion and expected debt, NPV also captures other critical factors that affect the long-term financial outcomes of college.
            </p>
            <p>
              To estimate NPV, CollegeNPV forecasts 40 years of cash flows, including expected income and student debt obligations, and discounts them back to a present value. This present value is further weighted by the university's completion rate, as the benefits of college are only realized for completers, while those who fail to finish may end up paying back debt without a degree. Finally, the weighted present value is compared with the estimated NPV of entering the workforce immediately out of high school, to compare the ROI of college to the minimum best alternative option.
            </p>
            <p>
              <strong>Interpreting Return On Investment:</strong>
            </p>
            <p>
              Here's an example to help explain how to interpret our return on investment estimates. Pretend your friend placed a $10 bill and a $100 bill on a table, and asked you to pick one to keep with no strings attached. Which would you pick? A rational individual would select the $100 bill of course.
            </p>
            <p>
              Since our ROI estimates are shown in present terms, they can be thought of in a similar way. A program with a $50,000 ROI means that we estimate a median student who begins that program is immediately $50,000 "richer" (just by showing up to class on the first day) than had they entered the workforce immediately after completing high school. The $50,000 reflects the present value of the expected lifetime benefit of the program, net of debt and in excess to entering the workforce immediately after high school. Of course, this is an estimate of a median student's outcomes, and real outcomes will vary based on individual circumstances.
            </p>
            <p>
            The goal of CollegeNPV's ranking system is to do the hard work for you and <strong>compose the estimated value of
            an education into a single number</strong> that is easy to understand and can be compared across different universities and fields of study.
            </p>
        </section>
      <section className="data-sources">
        <h2>Data Sources and Considerations</h2>
            <p>Our analysis is based on data from the Department of Education and the Bureau of Labor Statistics, complemented by expert judgement for nuanced assumptions.</p>
      </section>
      <AdInArticle />
    </div>
  );
}

export default MethodologyPage;