import { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo} from '../src/cnpv_logo.svg'

export default function Header () {
    const {setUserInfo, userInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for the dropdown
    const apiUrl = process.env.REACT_APP_API_URL || '';
    
    useEffect(() => {
        fetch(`${apiUrl}/profile`, {
            credentials: 'include',
        }).then(response => {
            if (response.ok) {
                response.json().then(userInfo => {
                    setUserInfo(userInfo);
                });
                if (location.pathname === '/login') {
                    navigate('/account');
                }
            } else {
                //console.log('User not logged in or other error occurred');
                setUserInfo(null);
                if (location.pathname === '/account') {
                    navigate('/login');
                }
            }
        }).catch(error => {
            console.error('Network error:', error);
        });
    }, [location, navigate, setUserInfo]);

    function logout() {
        
        fetch(`${apiUrl}/logout`, {
            credentials: 'include',
            method: 'POST',
        })
            .then(() => {
                setUserInfo(null);
                navigate('/');
            });

    };

    const userEmail = userInfo?.email;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

    return (
        <header>
            <Link to="/" className="logo" aria-label="Visit the home page" onClick={scrollToTop}>
                <Logo alt="CollegeNPV Logo" />
            </Link>
            <div className="header-right">
                <Link to={userEmail ? "/collegelistbuilder" : "/register"} className="header-register">
                        {userEmail ? "Profile" : "Register"}
                </Link>
                <div className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
                    <button className="hamburger" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>☰</button> {/* Carrot icon */}
                    {isDropdownOpen && (
                        <nav className="dropdown-menu">
                            {userEmail && (
                                <>
                                    <Link to='/collegerankings?query=&page=1&sort=rank_desc' onClick={() => setIsDropdownOpen(false)}>College Rankings</Link>
                                    <Link to='/programrankings?query=&page=1&sort=rank_desc' onClick={() => setIsDropdownOpen(false)}>Program Rankings</Link>
                                    <Link to="/collegelistbuilder" onClick={() => setIsDropdownOpen(false)}>College List Builder</Link>
                                    <Link to='/collegeroiheatmap' onClick={() => setIsDropdownOpen(false)}>College ROI Heatmap</Link>
                                    {/* <Link to="/account" onClick={() => setIsDropdownOpen(false)}>Account</Link> */}
                                    <Link to="/methodology" onClick={() => setIsDropdownOpen(false)}>About Us</Link>
                                    {/* <Link to='/create' onClick={() => setIsDropdownOpen(false)}>Create new post</Link> */}
                                    <a onClick={() => { logout(); setIsDropdownOpen(false); }}>Logout</a>
                                </>
                            )}
                            {!userEmail && (
                                <>
                                    <Link to='/collegerankings?query=&page=1&sort=rank_desc' onClick={() => setIsDropdownOpen(false)}>College Rankings</Link>
                                    <Link to='/programrankings?query=&page=1&sort=rank_desc' onClick={() => setIsDropdownOpen(false)}>Program Rankings</Link>
                                    <Link to='/collegeroiheatmap' onClick={() => setIsDropdownOpen(false)}>College ROI Heatmap</Link>
                                    {/* <Link to="/collegelistbuilder" onClick={() => setIsDropdownOpen(false)}>College List Builder</Link> */}
                                    <Link to="/register" onClick={() => setIsDropdownOpen(false)}>Register</Link>
                                    <Link to="/login" onClick={() => setIsDropdownOpen(false)}>Login</Link>
                                    <Link to="/methodology" onClick={() => setIsDropdownOpen(false)}>About Us</Link> 
                                </>
                            )}
                        </nav>
                    )}
                </div>
            </div>
        </header>
    );
}