import useDocumentTitle from "../useDocumentTitle";
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdResponsiveHorizontal from "../AdResponsiveHorizontal";
import CLBConfirmationModal from "../CLBConfirmationModal";
import CLBSchoolList from "../CLBSchoolList";

export default function CollegeListBuilder() {
    
    useDocumentTitle('College List Builder');
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const navigate = useNavigate();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [removeName, setRemoveName] = useState();
    const [removeId, setRemoveId] = useState();

    const [reachVisible, setReachVisible] = useState(false);
    const [targetVisible, setTargetVisible] = useState(true);
    const [safetyVisible, setSafetyVisible] = useState(false);

    const [reachColleges, setReachColleges] = useState([]);
    const [targetColleges, setTargetColleges] = useState([]);
    const [safetyColleges, setSafetyColleges] = useState([]);

    const [reachCount, setReachCount] = useState(0);
    const [targetCount, setTargetCount] = useState(0);
    const [safetyCount, setSafetyCount] = useState(0);

    const [reachRejection, setReachRejection] = useState(0);
    const [targetRejection, setTargetRejection] = useState(0);
    const [safetyRejection, setSafetyRejection] = useState(0);

    const [savedColleges, setSavedColleges] = useState([]);
    const [collegeDetails, setCollegeDetails] = useState([]);

    const fetchSavedColleges = async () => {
        try {
            const response = await fetch(`${apiUrl}/get-saved-colleges`, {
                method: 'GET',
                credentials: 'include', // Necessary for cookies to be sent with the request
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //console.log("Saved Colleges:", data);
            setSavedColleges(data);
            fetchCollegeDetails(data.map(college => college.UNITID));
        } catch (error) {
            console.error("Error fetching saved colleges:", error);
        }
    };

    const fetchCollegeDetails = async (unitIds) => {
        try {
            const response = await fetch(`${apiUrl}/colleges/info`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ unitIds }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const details = await response.json();
            //console.log("College Details:", details);

            // Classify the colleges into reach, target, and safety lists
            const reachColleges = [];
            const targetColleges = [];
            const safetyColleges = [];

            details.forEach(college => {
                const acceptanceRate = college.ADM_RATE;

                if (acceptanceRate < 0.3) {
                    reachColleges.push(college);
                } else if (acceptanceRate >= 0.3 && acceptanceRate <= 0.6) {
                    targetColleges.push(college);
                } else {
                    safetyColleges.push(college);
                }
            });

            // Sort each category by NET_NPV in descending order
            reachColleges.sort((a, b) => b.NET_NPV - a.NET_NPV);
            targetColleges.sort((a, b) => b.NET_NPV - a.NET_NPV);
            safetyColleges.sort((a, b) => b.NET_NPV - a.NET_NPV);

            setReachColleges(reachColleges);
            setTargetColleges(targetColleges);
            setSafetyColleges(safetyColleges);

            // Set counts for each category
            setReachCount(reachColleges.length);
            setTargetCount(targetColleges.length);
            setSafetyCount(safetyColleges.length);

            // Calculate rejection probabilities
            calculateRejectionProbabilities(reachColleges, setReachRejection);
            calculateRejectionProbabilities(targetColleges, setTargetRejection);
            calculateRejectionProbabilities(safetyColleges, setSafetyRejection);

            setCollegeDetails(details);
        } catch (error) {
            console.error("Error fetching college details:", error);
        }
    };

    const calculateRejectionProbabilities = (colleges, setRejectionState) => {
        if (colleges.length === 0) {
            setRejectionState(0);
            return;
        }

        const rejectionProbability = colleges.reduce((acc, college) => {
            const rejectionRate = 1 - college.ADM_RATE;
            return acc * rejectionRate;
        }, 1); // Start with 1 since we're multiplying probabilities

        setRejectionState(rejectionProbability);
    };

    const handleRemove = (UNITID, INSTNM) => {
        setRemoveName(INSTNM);
        setRemoveId(UNITID);
        setShowConfirmationModal(true);

        //console.log(`Removing college: ${INSTNM} with UNITID: ${UNITID}`);
    };

    function handleRemoveConfirm() {
        if (!removeId) return;

        fetch(`${apiUrl}/delete-college`, {
            method: 'DELETE',
            credentials: 'include', // Include credentials like cookies
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ UNITID: removeId }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text();
        })
        .then(result => {
            //console.log(result);
            // Refetch the saved colleges and their details after deletion
            fetchSavedColleges();
            setShowConfirmationModal(false);
        })
        .catch(error => {
            console.error("Error removing college:", error);
        });
    }

    useEffect(() => {
        fetchSavedColleges();
    }, []);
    
    function formatNumberAsPercentage(number, decimalPlaces = 0) {
        if (number !== null && number !== undefined) {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        }).format(value);
    };

    const navigateAndScrollToTop = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <div className="clb-main">
            <div className="clb-header">
                <h1>College List Builder</h1>
                <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="clb-header-brandmark" />
            </div>
            <p>Create a personalized list of Reach, Target, and Safety schools to maximize your college admissions success.</p>
            <div className="clb-section">
                <div className="clb-section-header" onClick={() => setReachVisible(!reachVisible)}>
                    <h2>Reach ({reachCount})</h2>
                    <span className="clb-toggle-icon">{reachVisible ? '-' : '+'}</span>
                </div>
                {reachVisible && (
                    <div>
                        {reachCount > 0 && (
                            <div className="clb-tooltip">These schools are very selective. There is a <strong>{formatNumberAsPercentage(reachRejection,1)} chance</strong> of complete rejection from your list.</div>
                        )}
                        <div className="clb-section-content">
                            <ul className="clb-school-list">
                                {reachColleges.map((school, index) => (
                                    <CLBSchoolList 
                                        key={school.UNITID}
                                        index={school.UNITID}
                                        INSTNM={school.INSTNM}
                                        UNITID={school.UNITID}
                                        NET_NPV={school.NET_NPV}
                                        ADM_RATE={school.ADM_RATE}
                                        ACTCM25={school.ACTCM25}
                                        ACTCM75={school.ACTCM75}
                                        SATCM25={school.SATCM25}
                                        SATCM75={school.SATCM75}
                                        handleRemove={handleRemove}
                                        formatCurrency={formatCurrency}
                                        formatNumberAsPercentage={formatNumberAsPercentage}
                                        navigateAndScrollToTop={navigateAndScrollToTop}
                                    />
                                ))}
                            </ul>
                            <div className="clb-button-section">
                                <button 
                                className="clb-add-button"
                                href={`/collegerankings?query=&page=1&sort=rank_desc`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateAndScrollToTop(`/collegerankings?query=&page=1&sort=rank_desc`);
                                }}
                                >+ Add more schools</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="clb-section">
                <div className="clb-section-header" onClick={() => setTargetVisible(!targetVisible)}>
                    <h2>Target  ({targetCount})</h2>
                    <span className="clb-toggle-icon">{targetVisible ? '-' : '+'}</span>
                </div>
                {targetVisible && (
                    <div>
                        {targetCount > 0 && (
                            <div className="clb-tooltip">These schools are moderately selective. There is a <strong>{formatNumberAsPercentage(targetRejection,1)} chance</strong> of complete rejection from your list.</div>
                        )}
                        <div className="clb-section-content">
                            <ul className="clb-school-list">
                                {targetColleges.map((school, index) => (
                                    <CLBSchoolList 
                                        key={school.UNITID}
                                        index={school.UNITID}
                                        INSTNM={school.INSTNM}
                                        UNITID={school.UNITID}
                                        NET_NPV={school.NET_NPV}
                                        ADM_RATE={school.ADM_RATE}
                                        ACTCM25={school.ACTCM25}
                                        ACTCM75={school.ACTCM75}
                                        SATCM25={school.SATCM25}
                                        SATCM75={school.SATCM75}
                                        handleRemove={handleRemove}
                                        formatCurrency={formatCurrency}
                                        formatNumberAsPercentage={formatNumberAsPercentage}
                                        navigateAndScrollToTop={navigateAndScrollToTop}
                                    />
                                ))}
                            </ul>
                            <div className="clb-button-section">
                                <button 
                                className="clb-add-button"
                                href={`/collegerankings?query=&page=1&sort=rank_desc`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateAndScrollToTop(`/collegerankings?query=&page=1&sort=rank_desc`);
                                }}
                                >+ Add more schools</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="clb-section">
                <div className="clb-section-header" onClick={() => setSafetyVisible(!safetyVisible)}>
                    <h2>Safety  ({safetyCount})</h2>
                    <span className="clb-toggle-icon">{safetyVisible ? '-' : '+'}</span>
                </div>
                {safetyVisible && (
                    <div>
                        {safetyCount > 0 && (
                            <div className="clb-tooltip">These schools are less selective. There is a <strong>{formatNumberAsPercentage(safetyRejection,1)} chance</strong> of complete rejection from your list.</div>
                        )}
                        <div className="clb-section-content">
                            <ul className="clb-school-list">
                                {safetyColleges.map((school, index) => (
                                    <CLBSchoolList 
                                        key={school.UNITID}
                                        index={school.UNITID}
                                        INSTNM={school.INSTNM}
                                        UNITID={school.UNITID}
                                        NET_NPV={school.NET_NPV}
                                        ADM_RATE={school.ADM_RATE}
                                        ACTCM25={school.ACTCM25}
                                        ACTCM75={school.ACTCM75}
                                        SATCM25={school.SATCM25}
                                        SATCM75={school.SATCM75}
                                        handleRemove={handleRemove}
                                        formatCurrency={formatCurrency}
                                        formatNumberAsPercentage={formatNumberAsPercentage}
                                        navigateAndScrollToTop={navigateAndScrollToTop}
                                    />
                                ))}
                            </ul>
                            <div className="clb-button-section">
                                <button 
                                className="clb-add-button"
                                href={`/collegerankings?query=&page=1&sort=rank_desc`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateAndScrollToTop(`/collegerankings?query=&page=1&sort=rank_desc`);
                                }}
                                >+ Add more schools</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <AdResponsiveHorizontal />
            <CLBConfirmationModal 
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onRemove={handleRemoveConfirm}
                UNITID={removeId}
                INSTNM={removeName} 
            />
        </div>
    );
}