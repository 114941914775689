import { ReactComponent as Icon} from '../icon.svg'
import React, { useEffect, useState } from 'react';
//import CollegeFOSRank from '../collegefosrank';
import CollegeFOSRank from '../collegefosrank';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useDocumentTitle from "../useDocumentTitle";
import Pagination from "../pagination";
import FOSSearchComponent from '../FOSSearchComponent';
import FOSFilterModal from '../FOSFilterModal';
import AdResponsiveHorizontal from '../AdResponsiveHorizontal';


export default function CollegeFOSRankings() {

    const apiUrl = process.env.REACT_APP_API_URL || '';

    const { page } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    useDocumentTitle('Browse College Majors Ranked by Return on Investment');

    const [fields,setFields] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    //UI states
    const [showFilterModal, setShowFilterModal] = useState(false);

    //filter states
    const initialPage = parseInt(query.get('page'), 10) || 1;
    const initialSearchQuery = decodeURIComponent(query.get('query') || '');
    const initialSelectedState = query.get('state') || '';
    const initialSort = query.get('sort') || 'rank_desc'; // Default to 20
    const initialPCIP = query.get('pcip') || '';
    const initialCIP = query.get('cip') || '';

    const [currentPage, setCurrentPage] = useState(initialPage);
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [sortOption, setSortOption] = useState(initialSort);
    const [currentPCIP, setPCIP] = useState(initialPCIP);
    const [currentCIP, setCIP] = useState(initialCIP);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const pageNum = parseInt(query.get('page') || page, 10);
        const searchQueryFromURL = decodeURIComponent(query.get('query') || '');
        const sortFromURL = query.get('sort') || '';
        const pcipFromURL = query.get('pcip') || '';
        const cipFromURL = query.get('cip') || '';
        
        setCurrentPage(pageNum);
        setSearchQuery(searchQueryFromURL);
        setSortOption(sortFromURL);
        setPCIP(pcipFromURL);
        setCIP(cipFromURL);
    
        const fetchUrl = `${apiUrl}/fieldofstudy?page=${pageNum}&query=${encodeURIComponent(searchQueryFromURL)}&sort=${sortOption}&pcip=${pcipFromURL}&cip=${cipFromURL}`;

        
        // Define the function to fetch field of study data
        const fetchFieldOfStudyData = async () => {
          try {
            const response = await fetch(fetchUrl);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const fields = await response.json();
            //console.log(fields); // Log the fetched data to the console
            setFields(fields.results);
            setTotalPages(fields.totalPages); 
            setTotalResults(fields.total);
          } catch (error) {
            console.error("Could not fetch data:", error);
          }
        };
    
        // Call the fetch function
        fetchFieldOfStudyData();
      }, [location.search]);

      //Pagination Function

      const handlePageChange = (newPage) => {
        // Construct the search query including test score type and target score
        const searchParams = new URLSearchParams({
            page: newPage,
            query: encodeURIComponent(decodeURIComponent(searchQuery)),
            sort: encodeURIComponent(sortOption)
        });

        if (currentPCIP) {
            searchParams.set('pcip', currentPCIP);
        }

        if (currentCIP) {
            searchParams.set('cip', currentCIP);
        }
    
        // Use navigate to update the URL with the new search parameters
        navigate({
            pathname: '/programrankings',
            search: `?${searchParams.toString()}`
        });
    
        window.scrollTo(0, 0);
        //console.log('Updating page with filters:', searchParams.toString());
    };

    function formatNumberWithCommas(number) {
        return number ? number.toLocaleString() : "";
    };

    //URL Updates
    const updateURL = (newPage, newSearchQuery = searchQuery, newPCIP = currentPCIP, newCIP = currentCIP, newSortOption = sortOption) => {
        const searchParams = new URLSearchParams();
        
        if (newSearchQuery) searchParams.set("query", encodeURIComponent(decodeURIComponent(newSearchQuery)));
        if (newPCIP) searchParams.set("pcip", newPCIP);
        if (newCIP) searchParams.set("cip", newCIP);
        searchParams.set("page", newPage.toString());
        searchParams.set("sort", newSortOption); // Include sort option in URL
        
        navigate(`/programrankings/?${searchParams.toString()}`, { replace: true });
    };

    //Search functions

    const updateURLWithSearch = (query) => {
        // Assuming page resets to 1 on new search
        updateURL(1, query); // Re-use your existing updateURL logic
        //console.log('Updating URL with search!');
    };

    const handleSearchResults = (searchResults) => {
        setFields(searchResults.results);
        setTotalPages(searchResults.totalPages); 
        setTotalResults(searchResults.total);

        const newPage = 1;
        updateURL(newPage, searchQuery);
        //console.log('Updating URL with search results!');
      };

    //Sort functions

    const SORT_OPTIONS = [
        { label: 'Rank: High', value: 'rank_desc' },
        { label: 'Rank: Low', value: 'rank_asc' },
    ];

    const handleSortChange = (event) => {
        const newPage = 1;
        const selectedSortOption = event.target.value;
        setSortOption(selectedSortOption);
        updateURL(newPage, searchQuery, currentPCIP, currentCIP, selectedSortOption);
    };

    //Filter functions
    const handleFilterModal = () => {
        setShowFilterModal(true);
    }

    const handleFilterApply = (newSelectedPCIP, newSelectedCIP) => {
        setPCIP(newSelectedPCIP);
        setCIP(newSelectedCIP);
    
        const newPage = 1;
        updateURL(newPage, searchQuery, newSelectedPCIP, newSelectedCIP);

            // Construct the fetch URL with all necessary parameters, including test score type and target score
        let fetchUrl = `${apiUrl}/fieldofstudy?page=${newPage}&query=${encodeURIComponent(searchQuery)}&pcip=${encodeURIComponent(newSelectedPCIP)}&cip=${encodeURIComponent(newSelectedCIP)}`;
    
        fetchUrl += `&sort=${sortOption}`
        
        fetch(fetchUrl)
        .then(response => response.json())
        .then(fieldres => {
            setFields(fieldres.results);
            setTotalPages(fieldres.totalPages); 
            setTotalResults(fieldres.total);
        })
        .catch(error => {
            console.error("Error fetching data with state filter:", error);
        });
    
        // Close the filter modal
        setShowFilterModal(false);
    };

    return (
        <div className="fos-main">
            <h2 className="fos-search-results">{formatNumberWithCommas(totalResults)} degree programs</h2>
            <FOSSearchComponent
                onSearchResults={handleSearchResults}
                currentPCIP={currentPCIP}
                currentCIP={currentCIP}
                sortOption={sortOption}
                onUpdateURL={updateURLWithSearch}
            />
            <div className="filter-sort-container">
                <button className="filter-button" onClick={handleFilterModal}>
                    Filter
                    {(initialPCIP !== '' || initialCIP !== '') && <span className="filter-active-indicator"></span>}
                </button>
                <select onChange={handleSortChange} value={sortOption} className="sort-dropdown" aria-label="Sort Dropdown">
                    {SORT_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            <FOSFilterModal 
                show={showFilterModal} 
                onClose={() => setShowFilterModal(false)} 
                onApplyFilters={handleFilterApply}
                initialPCIP={initialPCIP}
                initialCIP={initialCIP}
            />
            <div className="fos-container">
                {fields.length > 0 && fields.map((field) => (
                    <CollegeFOSRank key={field._id} {...field} />
                ))}
            </div>
            <Pagination page={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            <AdResponsiveHorizontal />
        </div>
    );
}